.site-footer {
    margin-top: 100px;
    background: {
        image: linear-gradient(0deg, rgba($black, 0.5), rgba($black, 0.5)), url('/images/footer-bg.jpg');
        size: cover;
    }
    padding: {
        top: 80px;
        bottom: 80px;
    }

    form {
        .form-group {
            position: relative;

            &.focused {
                label {
                    top: -25px;
                    left: 0px;
                    font-size: 12px;
                }
            }
        }
        label {
            position: absolute;
            top: 10px;
            left: 20px;
            transition: all 300ms ease-in-out;
        }
        input:not([type=checkbox]):not([type=radio]) , textarea {
            border: none;
            background-color: rgba($white, .3) !important;
            padding: 12px 20px;
            border-radius: 0 !important;
            color: $white !important;
            margin-bottom: 35px;
        }
        input:not([type=checkbox]):not([type=radio]) {
            height: 47px;
        }
        button {
            color: $white;
            text-decoration: underline;
            text-transform: uppercase;
            transition: all 300ms ease;
            &:hover {
                color: $pastel-1;
            }
        }
    }
}


.menu-footer-menu {
    padding: 0;
    list-style-type: none;
    li a {
        color: $white;
        &:hover {
            color: $pastel-1;
        }
    }
}


.ui-slider {
    &.ui-slider-horizontal {

        height: 6px;

        .ui-slider-range, &.ui-widget-content {

            border: 1px solid $pastel-1;

        }

        .ui-widget-header {
            background: $pastel-1;
            border: none;
        }

        .ui-slider-handle {
            border-radius: 50%;
            width: 14px;
            height: 14px;
            background: $pastel-1;
            border-color: $pastel-1;
        }

    }
}



header {
    position: fixed;
    width: 100vw;
    top:0;
    z-index: 99999;
   // box-shadow: 0 10px 10px 0px rgb(0 0 0 / 5%);
    background-color: transparent;
    transition: background-color 300ms ease;
    .header-logo {
        transition: all 300ms ease;
        height: 20px;

    }
    &.scrolled {
        background-color: $black;
        border-bottom: 1px solid rgba($white,.4);
        .header-logo {
            opacity: 1;
        }
    }

}

.page-home {
    header {
        .header-logo {
            opacity: 0;
        }
        &.scrolled {
            .header-logo {
                opacity: 1;
            }
        }
    }
}

.mini-cart {
    position: relative;
    a, a.visited {
        color: $white;
    }
    .badge-main {
        background-color: $white;
        position: absolute;
        right: -2px;
        top: -5px;
        color: $black;
        z-index: 999;
    }
}

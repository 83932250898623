.product-list-item {
    position: relative;
    //border: 1px solid $pastel-1;
    height: 100%;
    display: block;
    color: $font-color;
    text-decoration: none !important;
    //background-color: $white;

    .new-label {
        position: absolute;
        display:none;
        top: 0;
        //display: block;
        z-index: 10;
        background-color: $pastel-2-accent;
        padding: 2px 10px 2px 10px;
        color: #ffffff;

    }
    .sale-label {
        position: absolute;
        top: 0;
        display: block;
        z-index: 10;
        background-color: $pastel-1;
        padding: 2px 10px 2px 10px;
        color: #ffffff;

    }

    /*&:before {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 1px solid $pastel-1-accent;
        z-index: 4;
        opacity: 0;
        transition: all 500ms ease;
    }*/


    .image-container {
        position: relative;
        padding-bottom: 100%;
        overflow: hidden;
        background-color: $black;
        img {
            position: absolute;
            top:0;
            left:0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
    }


    .product-info {
        hyphens: auto;

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 15px;
            width: calc(100% - 30px );
            height: 100%;
            opacity:1;
            transition: all 400ms ease;
            background-color: rgba($white, .3);
        }
        position: relative;

        .product-title {
            margin-bottom: 0;
            font-size: 1rem;
            font-weight: normal;
            color: $white;
            @include media-breakpoint-down(md) {
                font-size: 1rem;
            }
        }
        .product-price {
            font: {
                family: $main-font;
                size: 18px;
                weight: bold;
            }
            color: $white;
        }
    }
    &:hover {
        color: $font-color;

        .product-info {
            &:before {
                background-color: $pastel-1;
            }
        }
        &:before, &:after {
            width: 100%;
            height: 100%;
            opacity: 1;
        }

    }
    .open-icon {
        width: 20px;
    }

    .tip-of-the-day-label {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: #5b6d32;
        color: #FFF;
        padding: 5px 10px;
    }

}

.coming-soon {
    h2 {
        display: block;
        font-size: 2.5rem;
        &:after {
            display: block;
            content: url('/images/curl.svg');
            width: 200px;
            margin: 20px auto;
        }
    }
}

.pager {
    a, a.visited {
        color: $white;
        &:hover {
            color: $pastel-1;
        }
    }
    .active {
        color: $pastel-1;
    }
    .disabled {
        color: $gray-600
    }
}

#cardTextPopup {
    z-index: 50000;

    .add-product-dialog {
        min-height: calc(100vh - 60px);
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow: auto;
        @media(max-width: 768px) {
            min-height: calc(100vh - 20px);
        }

        .modal-content {
            background-color: #272727;
            color: white;
            border: 1px solid white;

            .modal-header {
                border: 0;
            }

            .modal-footer {
                border: 0;
            }
        }

        .btn-primary {
            background-color: #5b6d32;
            color: #fff;
            padding: 5px 40px;
            border-radius: 5px;
            display: inline-block;
            outline: 0;
            border: 0;
            font-size: 18px;
            text-decoration: none !important;
            transition: all 300ms ease-in-out;
        }
    }
}

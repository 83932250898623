section#home-header {
    height: 100vh;
    background: {
        image: linear-gradient(0deg, rgba($black, 0.3), rgba($black, 0.3)), url('/images/home-header.jpg');
        size: cover;
        position: center;
    }

}

section#home-intro {

    position: relative;
    min-height: 600px;
    margin-top: 90px !important;

    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        color: $white;
        h1 {
            font-weight: 300;
            color: $white;
            strong {
                font-weight: 800;
            }
        }
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        min-height: 600px;
        height: 100%;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        overflow: hidden;
        @include media-breakpoint-down(md) {
            opacity: .5;
        }
        >div {
            height:100%;
            text-align: right;
        }
        img {
            min-height: 800px;
           object-fit: cover;
        }


        &:after {
            position: absolute;
            content: "";
            top: 0;
            right: -150px;
            display: block;
            height: 100%;
            width: 481px;
            background-repeat: no-repeat;
            background-image: url('/images/lijnen-bloem.svg');
            background-size: 116%;
            opacity: .25;
        }
    }


}

section#home-unique, section#home-automaat {

    position: relative;
    min-height: 700px;

    .content-div {

        @include media-breakpoint-up(lg) {
                min-height: 700px;
                display: flex;
                align-items: center;
        }


        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        color:$white;
        h2 {
            font-size: 48px;
            font-weight: 300;
            color: $white;
            strong {
                font-weight: 800;
            }
        }
        .uk-button.uk-button-default:hover {
            background-color: #5c6a75;
            color: $white;
        }

    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 100%;
        left: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        @include media-breakpoint-down(md) {
            opacity: .5;
        }
        >div {
            height: 100%;
            text-align: left;
        }
        img {
            min-height: 700px;
            height: 100%;
            object-fit: cover;
        }
    }

}

section#new-products {
    @extend .py-5;
    @extend .mb-4;
    .uk-position-small[class*=uk-position-center-left]{

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(-120%) translateY(30px);
        font-size: 25px;

    }
    .uk-position-small[class*=uk-position-center-right] {

        background-color: $pastel-2;
        color: $white;
        padding: 11px 20px ;
        transform: translateX(120%) translateY(30px);
        font-size: 25px;

    }
    .new-content > div:first-of-type {

        text-align: center;
        @extend .mb-5;

    }
    h2 {

        display:inline-block;
        margin: 0 auto;
        text-align: center;
        text-transform: uppercase;
        position: relative;
        //border-bottom: 3px dashed $pastel-2;

        &:after {

            content: "";
            position: absolute;
            bottom: -10px;
            left: -10px;
            width: calc(100% + 10px);
            height: 3px;
            background-image: linear-gradient(to right, transparent 50%, $pastel-2 50%);
            background-size: 22px 100%;

        }

    }

    li {
        padding-top: 40px;

        .new-product-item {
            position: relative;
            display: block;
            &:before {

                content: "";
                position: absolute;
                top: -30px;
                left: calc(50% - 50px);
                width: 50%;
                padding-bottom: 24%;
                background-image: url(/images/tape.svg?052e661…);
                background-size: 100%;
                background-position: bottom center;
                z-index: 999;
                background-repeat: no-repeat;
                transform-origin: center;
                opacity: .8;

            }

        }
        &:nth-child(2n) .new-product-item:before {

            //left: 50px;
            transform: scaleX(-1);

        }
        &:nth-child(3n) .new-product-item:before {

            //left: 50px;
            transform:  rotate(20deg);

        }
        &:nth-child(4n) .new-product-item:before {

            //left: 50px;
            transform: rotate(-20deg) scaleX(-1);

        }

    }

}

section#home-social {
    background-color: $pastel-3;
    background-image: url(/images/plane.svg);
    background-repeat: no-repeat;
    background-size: 350px;
    background-position: 0 -55px ;
    height: 240px;
    @extend .py-4;
    @extend .d-flex;
    @extend .align-items-center;
    h3 {
        font-size: 32px;
        font-weight: 700;
        font-style: normal;
        letter-spacing: normal;
    }
    h4 {
        margin-top: -15px;
        font-size: 32px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
    }

    .social-buttons {
        ul {
            @extend .row;
            @extend .px-0;
            list-style-type: none;
            li {
                @extend .col-5;
                @extend .py-3;
                &:nth-child(3) {
                    @extend .offset-lg-1;
                }
                a {
                    @extend .uk-button;
                    @extend .w-100;
                    background-color: $white;
                    box-shadow: 0 0 15px $pastel-3-accent;
                    border-radius: 5px;
                    font-size: 18px;
                    padding-top: 7px;
                    padding-bottom: 7px;
                    transition: all 300ms ease-in-out;
                    &:hover {
                        background-color: $button-dark;
                        color: $white;
                    }
                    i {
                        font-size: 20px;
                    }
                }
            }
        }
    }
}

section#home-designs {
    background-color: $pastel-1;
    position: relative;
    height: 800px;
    color: $pastel-1-accent;
    .content-div {
        position: relative;
        z-index: 10;
        font-size: 20px;
        font-weight: 400;
        font-style: normal;
        letter-spacing: normal;
        line-height: 28.13px;
        h2 {
            font-size: 48px;
            font-weight: 700;
        }
        .uk-button.uk-button-default:hover {
            background-color: #f7cc49;
            color: $black;
        }
    }
    .container {
        height: 100%;
        color: $black;
    }
    .filled-image {
        position: absolute;
        height: 800px;
        right: 0px;
        padding-left: 0;
        padding-right: 0;
        top: 0;
        >div {
            text-align: right;
        }
        img {
            height: 800px;
            object-fit: cover;
        }
    }
    &:before {
        position: absolute;
        z-index: 1;
        width: 45%;
        height: 80%;
        bottom: 0;
        left: 0;
        color: $pastel-1-accent;
        content: "";
        background-image: url(/images/w.svg);
        background-size: 100%;
        background-position: bottom left;
        background-repeat: no-repeat;
        opacity: .20;
    }
}


.menu-category-menu {
    list-style-type: none;
    padding: O;
    li {
        display: inline-block;
        background-image: none !important;
        a, a.visited {
            color: $white;
            display:block;
            padding: 0px 30px;
            text-transform: uppercase;
            font-size: 22px;
            line-height: 22px;
        }
        & + li {
            border-left: 1px solid $white;
        }
    }
}

section#product-slider {
    overflow-x: hidden;
    margin-top: 40px;
    margin-bottom: 100px;
     .col-auto {
        padding-left: 60px;
    }
    .webshop-link {
        display: block;
        background-color: $pastel-1;
        height: 530px;
        width: 290px;
        text-decoration: none !important;
        transition: all 300ms ease;
        @include media-breakpoint-down(md) {
            width: 100%;
            height: 400px;
        }
        &:hover {
            background-color: lighten($pastel-1, 10%)
        }
        h3 {
            font-size: 32px;
            color: $white;
            font-weight: 800;
            text-transform: uppercase;
        }
        .fakelink {
            text-decoration: underline;
            color: $white;
            text-transform: uppercase;
        }
    }
    .uk-slider-container {
        width: 125%;
    }
    .uk-position-small {
        padding: 7px 12px;
        background-color: $black;
    }
    .image-container {
        padding-bottom: 100%;
        background-size: cover;
        background-position: center;
        margin: 15px;
    }
}

section#home-inspiration {
    padding-top: 100px;
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    .slide-image {
        transform-origin: top center;
    }
}

section#home-about {
    margin-top: 100px;
    margin-bottom: 100px;
    padding-top: 100px;
    padding-bottom: 100px;
    background-size:100%;
    background-image: url('/images/home-about.png');
    position: relative;
    overflow-x: hidden;
    &:after {
        position: absolute;
        content: "";
        top: 0;
        right: -150px;
        display: block;
        height: 100%;
        width: 481px;
        background-repeat: no-repeat;
        background-image: url('/images/lijnen-bloem.svg');
        background-size: 106%;
        opacity: .25;
        pointer-events: none;
    }
    h2 {
        font-size: 48px;
        font-weight: 300;
        color: $white;
        text-transform: uppercase;

        strong {
            font-weight: 800;
        }
    }
    .content-div {
        font-size: 20px;
    }

    a , a:visited{
        color: $white;
        text-decoration: underline;
        text-transform: uppercase;
        transition: all 300ms ease;
        &:hover {
            color: $pastel-1;
        }
    }

}

.insp-slider-image {
    padding-left: 80px; padding-right: 80px;
    @include media-breakpoint-down(md) {
        padding-left: 15px; padding-right: 15px
    }
}



@media (max-width: 1199px) {
    #popupModal {
        margin-top: 75px !important;
    }
}




body {
    font-family: $main-font;
    color: $white;
    background-color: $black;
}

.uk-button.uk-button-default {
    width: 271px;
    padding-top:10px;
    padding-bottom: 10px;
    border-radius: 7px;
    background-color: $white;
    transition: all 300ms ease-in-out;
    font-size: 18px;
    &:hover {
        background-color: $black;
        color: $white;
    }
}
.uk-button.btn-dark {
    background-color: $main-color;
    color: $white;
}

input:not([type=checkbox]):not([type=radio]) , textarea, select:not(.uk-select) {
    border: none;
    background-color: rgba($white, .1) !important;
    padding: 12px 20px;
    border-radius: 0 !important;
    color: $white !important;


}
select:not(.uk-select) {
    border: none;
    background-color: #3c3c3c !important;
    padding: 12px 20px;
    border-radius: 0 !important;
    color: $white !important;
    height: 47px;

}
input:not([type=checkbox]):not([type=radio]) {
    height: 47px;
}
input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
    box-shadow: none !important
}

.uk-select:not([multiple]):not([size]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 20px;
    background-image: url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='16' height='16' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='black'/></g></svg>");
    background-repeat: no-repeat;
    background-position: 94% 50%;
    border: none;
    background-color: rgba($white, .1) !important;
    padding: 12px 20px;
    height: 47px;
    border-radius: 0 !important;
    color: $white !important;

}

.price-input  {
    position: relative;
    &:before {
        position: absolute;
        content: "€";
        top: 12px;
        left: 22px;
        color: $white;
    }
    input {
        text-align: right;
    }
}
.break {
    flex-basis: 100%;
    height: 0;
}

.qty-selector {
    position: relative;
    .qty-select-open {
        height: 47px;
        width: 45px;
        background-color: $pastel-1;
        border: 1px solid $pastel-1;
        color: $white;
        text-align: center;
        margin-left: -4px;
        padding: 10px 0;
        cursor: pointer;
    }
    .qty-field {
        border: 1px solid rgba($white, .3);
        height: 45px; width: 80px;
        text-align: center;
        vertical-align: top;

    }
    .qty-select-options {
        position: absolute;
        left: 0;
        z-index: 99;
        top: 100%;
        background-color: $black;
        border: 1px solid $pastel-1;
        border-top: none;
        width: 125px;
        height: 200px;
        overflow-y: auto;
        transform: scaleY(0);
        transform-origin: top;
        opacity: 0;
        transition: all 300ms ease-in-out;

        &.open {
            transform: scaleY(1);
            opacity: 1;

        }
        /* width */
        &::-webkit-scrollbar {
            width: 10px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: rgba($white, .3);
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $pastel-1;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $pastel-1;
        }
        scrollbar-width: thin;
        scrollbar-color: $pastel-1 rgba($white, .3);
        div {
            &:hover {
                background-color: rgba($pastel-1, .6);
            }
        }
    }
}


$items: 4;
$transition-duration: 0.5s;
$transition-delay: 0.05s;

#toggle {
    position: absolute;
    left: -100%;
    top: -100%;

}

.toggle-container {
    position: absolute;
    top: 0px;
    right: 0px;

        width: 30px;
        height: 30px;
    &:before {
        display:none !important;
    }
}
#toggle:focus {

    & ~ .toggle-container .button-toggle {
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.7), inset 0 0 0 20px rgba(0, 0, 0, 0.7);
    }
}

// Styles for the 'open' state, if the checkbox is checked
#toggle:checked {
    // Any element you need to change the style if menu is open goes here, using the sibling selector (~) as follows

    // Making the "X" icon using `:before` and `:after` pseudo-elements
    & ~ .toggle-container .button-toggle {
        box-shadow: 0 0 0 550px rgba(0, 0, 0, 0.7), inset 0 0 0 20px rgba(0, 0, 0, 0.7);

        &:hover {
            box-shadow: 0 0 0 550px rgba(0, 0, 0, 0.6), inset 0 0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 8px rgba(0, 0, 0, 0.6), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
        }

        &:before {
            transform: translateY(-50%) rotate(45deg) scale(1);
        }

        &:after {
            transform: translateY(-50%) rotate(-45deg) scale(1);
        }
    }

    &:focus ~ .toggle-container .button-toggle {
        box-shadow: 0 0 0 550px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1), 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }

    // Open nav
    & ~ .nav {
        margin-bottom: 100px;
        pointer-events: auto;
        transform: translate( -150px, 50px);

        // Restoring nav items from "lines" in the menu icon
        .nav-item {
            color: $white;
            letter-spacing: 0;
            height: 60px;
            line-height: 40px;
            margin-top: 0;
            opacity: 1;
            transform: scaleY(1);
            transition: $transition-duration, opacity 0.1s;
            font-size: 30px;
            &.webshop-item {
                color: lighten($pastel-1, 20%);
            }

            // Setting delays for the nav items in open transition
            @for $i from 1 through $items {
                &:nth-child(#{$i}) {
                    $delay: ($items - $i) * $transition-delay;
                    transition-delay: $delay;
                    &:before {
                        transition-delay: $delay;
                    }
                }
            }

            // Hiding the lines
            &:before {
                opacity: 0;
            }
        }
    }

    // Dummy content
    & ~ .dummy-content {
        padding-top: 30px;

        &:before {
            background-color: rgba(0, 0, 0, 0.3);
        }
    }
}


/* Toggle button */

.button-toggle {
    position: absolute;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 25px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    transition: $transition-duration + 0.1;

    // Shadow on hover
    &:hover {
        box-shadow: 0 0 0 8px rgba(0, 0, 0, 0.1), inset 0 0 0 20px rgba(0, 0, 0, 0.1);
    }

    // Making the "X" icon using `:before` and `:after` pseudo-elements
    // Initially hidden because `scale(0)` transformation

    &:before, &:after {
        position: absolute;
        content: '';
        top: -14px;
        left: -51px;
        width: 100%;
        height: 2px;
        background-color: $white;
        border-radius: 5px;
        transition: $transition-duration;
    }

    &:before {
        transform: translateY(-50%) rotate(45deg) scale(0);
    }

    &:after {
        transform: translateY(-50%) rotate(-45deg) scale(0);
    }
}


/* Menu */

.nav {
    display: inline-block;
    //margin: 25px 25px 20px;
    position: absolute;
    top: 0;
    left: 0;
    // Don't want pointer-events as menu is closed
    pointer-events: none;
    transition: $transition-duration;
}

// Showing nav items as lines, making up the hamburger menu icon
.nav-item {
    position: relative;
    display: inline-block;
    float: right;
    clear: both;
    color: transparent;
    font-size: 14px;
    letter-spacing: -7px;
    height: 7px;
    line-height: 7px;
    text-transform: uppercase;
    white-space: nowrap;
    transform: scaleY(0.2);
    transition: $transition-duration, opacity 1s;

    // Setting delays for the nav items in close transition
    @for $i from 1 through $items {
        &:nth-child(#{$i}) {
            $delay: ($i - 1) * $transition-delay;
            transition-delay: $delay;
            &:before {
                transition-delay: $delay;
            }
        }
    }

    // Adjusting width for the first line
    &:nth-child(1) {
        letter-spacing: -7px;
    }

    // Adjusting width for the second line
    &:nth-child(2) {
        letter-spacing: -5px;
    }

    // Adjusting from the fourth element onwards
    &:nth-child(n + 4) {
        letter-spacing: -5px;
        margin-top: -7px;
        opacity: 0;
    }

    // Getting the lines for the hamburger menu icon
    &:before {
        position: absolute;
        content: '';
        top: 50%;
        left: 0;
        width: 100%;
        height: 2px;
        background-color: $white;
        transform: translateY(-50%) scaleY(5);
        transition: $transition-duration;
    }
}
.lg-outer {
    z-index: 99999999 !important;
}

.square-link {
    display:block;
    position:relative;
    color: $white;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 22px;
    transition: all 300ms ease;
    &:hover {
        color: $white;
        font-size: 28px;
    }
    &:before {
        content: '';
        display: block;
        width: 100%;
        padding-bottom: 100%;
    }
    .content {
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 100%;
        text-align: center;
    }
}

.back-btn {
    color: $white !important;
    &:hover {
        color: $pastel-1 !important;
        text-decoration: none;
    }
}

.google-rc-message {
    margin-top:-25px;
    color: $gray-500;
    font-size: 14px;
    a {
        color: $gray-500;
    }
}
.grecaptcha-badge { visibility: hidden; }
